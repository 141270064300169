import React from 'react';

const Arcade = ({ ...props }) => {
  return (
    <svg
      width={168}
      height={168}
      viewBox='0 0 168 168'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        {...props}
        d='M167.99 84.1021C167.99 75.6999 161.179 68.8887 152.778 68.8887H140.937L151.197 62.9724C158.475 58.7663 160.961 49.4692 156.765 42.2006C152.569 34.932 143.263 32.4362 135.986 36.6323L125.735 42.5586L131.651 32.3069C135.856 25.0283 133.361 15.7312 126.083 11.5252C118.815 7.32906 109.51 9.81491 105.304 17.0935L99.3884 27.3451V15.5125C99.3884 7.12025 92.5779 0.299072 84.1767 0.299072C75.7756 0.299072 68.9651 7.11031 68.9651 15.5125V27.3551L63.0396 17.1034C58.8439 9.8348 49.538 7.339 42.2603 11.5351C34.9826 15.7312 32.497 25.0383 36.6927 32.3168L42.6083 42.5685L32.3479 36.6422C25.0801 32.4461 15.7742 34.932 11.5786 42.2105C7.37303 49.4792 9.86853 58.7862 17.1462 62.9823L27.3967 68.8987H15.5555C7.1543 68.8987 0.343872 75.7099 0.343872 84.1121C0.343872 92.5142 7.14436 99.3255 15.5555 99.3255H27.3867L17.1363 105.242C9.85859 109.448 7.37303 118.755 11.5686 126.024C15.7742 133.302 25.0702 135.788 32.3479 131.592L42.5983 125.676L36.6827 135.927C32.4772 143.206 34.9727 152.503 42.2504 156.709C49.5281 160.905 58.8241 158.419 63.0296 151.141L68.9552 140.889V152.732C68.9552 161.144 75.7656 167.945 84.1668 167.945C92.568 167.945 99.3784 161.144 99.3784 152.732V140.889L105.294 151.141C109.5 158.419 118.796 160.905 126.073 156.709C133.351 152.503 135.846 143.206 131.641 135.927L125.715 125.676L135.966 131.592C143.243 135.798 152.54 133.302 156.745 126.024C160.951 118.745 158.455 109.448 151.177 105.242L140.927 99.3255H152.768C161.169 99.3255 167.98 92.5142 167.98 84.1121L167.99 84.1021Z'
        fill='#2E2C2D'
      />
    </svg>
  );
};

export default Arcade;
