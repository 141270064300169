import React from 'react';
import { Bounce } from 'react-awesome-reveal';

import FnBIMG from '../../assets/FnBIMG.png';

const FnB = () => {
  return (
    <section className='fnb row space-between align-items-center'>
      <Bounce triggerOnce direction='left'>
        <div className='text'>
          <h3>Our F&B partner</h3>
          <p>
            Pablo & Abdo blends its menu seamlessly with BOOM ROOM with a unique
            blend of local and international cuisine.
          </p>
        </div>
      </Bounce>
      <Bounce triggerOnce direction='right'>
        <div className='img-wrap'>
          <img src={FnBIMG} alt='F&B' />
        </div>
      </Bounce>
    </section>
  );
};

export default FnB;
