import React, { useLayoutEffect } from 'react';
import { Bounce } from 'react-awesome-reveal';

import BRLogo from '../../assets/BoomRoom.png';

import spinStar from '../../assets/BoomRoomStarVector.svg';

import defaultIMG from '../../assets/Default.png';
import variantIMG from '../../assets/Variant2.png';
import singerPng from '../../assets/singerPNG.png';
import singerBG from '../../assets/singerBG.png';
import mainShowIMG from '../../assets/singerPNG2.png';
import circles from '../../assets/circles.png';

import Golf from '../../SVGs/Golf/Golf';
import Darts from '../../SVGs/Darts/Darts';
import Bowling from '../../SVGs/Bowling/Bowling';
import Arcade from '../../SVGs/Arcade/Arcade';
import LiveStage from '../../SVGs/LiveStage/LiveStage';

const AboutUs = () => {
  // const onScroll = () => {};

  // useLayoutEffect(() => {
  //   window.addEventListener('scroll', onScroll);
  //   return () => window.removeEventListener('scroll', onScroll);
  // }, []);

  return (
    <section className='aboutus'>
      <img src={spinStar} alt='BoomRoom-spin' className='spin-circle spin-up' />
      <img
        src={spinStar}
        alt='BoomRoom-spin'
        className='spin-circle spin-dwn'
      />
      <div className='top-section'>
        <Bounce triggerOnce direction='left'>
          <img src={BRLogo} alt='BoomRoom-Logo' className='boomLogo' />
          <p>
            Immerse yourself in a world of limitless fun and <br />
            excitement as you explore our dynamic space. <br />
            With a diverse offering of fun activities
          </p>
        </Bounce>

        <div className='games'>
          <Bounce triggerOnce direction='right'>
            <div className='row space-between width-100'>
              <div className='game darts'>
                <Darts />
                <h6>Darts</h6>
              </div>
              <div className='game golf'>
                <Golf />
                <h6>
                  9-Hole
                  <br />
                  Mini-Golf
                </h6>
              </div>
              <div className='game arcade'>
                <Arcade />
                <h6>Arcade</h6>
              </div>
              <div className='game bowling'>
                <Bowling />
                <h6>Bowling</h6>
              </div>
            </div>
          </Bounce>
        </div>
        <Bounce triggerOnce direction='right'>
          <h5>And the classic games of</h5>
          <div className='row sports'>
            <h4>Foosball</h4>
            <h3>&</h3>
            <h4>Billiards</h4>
            <h3>&</h3>
            <h4>Shuffleboard</h4>
          </div>
        </Bounce>
      </div>
      <Bounce triggerOnce direction='right'>
        <div className='btm-section width-100'>
          <div className='stage'>
            <LiveStage />
            <h6>
              Live Entertainment
              <br />
              Stage
            </h6>
          </div>
          <ul className='actions'>
            <li>Open mics</li>
            <ul>
              <li>Live music</li>
              <ul>
                <li>Dance nights</li>
                <ul>
                  <li>Stand up comedy</li>
                </ul>
              </ul>
            </ul>
          </ul>
          {/* <img
            src={defaultIMG}
            alt='BoomRoom'
            onMouseOver={(e) => (e.currentTarget.src = variantIMG)}
            onMouseOut={(e) => (e.currentTarget.src = defaultIMG)}
            className='framePIC'
            style={{ transformOrigin: 'center bottom' }}
          /> */}
          <div className='main-container'>
            <img src={mainShowIMG} alt='singer' className='main-img' />
            <div className='avatar-container c2'>
              {/* <img src={singerPng} alt='singer' className='contained-img' /> */}
              <img src={singerBG} alt='singer' className='contained-img' />
            </div>
            <div className='avatar-container'></div>
            <img src={circles} alt='overlay-circles' className='circles' />
          </div>
        </div>
      </Bounce>
    </section>
  );
};

export default AboutUs;
