import React from 'react';
import { Bounce } from 'react-awesome-reveal';

import highlight1 from '../../assets/highlight-1.png';
import highlight2 from '../../assets/highlight-2.png';
import highlight3 from '../../assets/highlight-3.png';
import highlight4 from '../../assets/highlight-4.png';
import highlight5 from '../../assets/highlight-5.png';

// import { IoIosArrowRoundForward } from 'react-icons/io';

const Attractions = () => {
  // const arcade = {
  //   background: `url(${highlight1})`,
  // };
  // const bowling = {
  //   background: `url(${highlight2})`,
  // };
  // const golf = { background: `url(${highlight3})` };
  // const stage = { background: `url(${highlight4})` };
  // const darts = { background: `url(${highlight5})` };
  return (
    <Bounce triggerOnce direction='right'>
      <section className='attractions'>
        <h1>Attractions Highlights</h1>
        <div className='highlights'>
          <article className='single-highlight'>
            <img src={highlight1} alt='arcade' className='highlight-img' />
            <div className='highlight-content'>
              <h4 className='title'>Arcade:</h4>
              <p>
                Rediscover the joy of gaming at BOOM ROOM's arcade, a place for
                both retro enthusiasts and modern gamers alike. Elevate your
                Cairo gaming experience at BOOM ROOM today.
              </p>
              {/* <button className='btn-readMore'>
                READ MORE <IoIosArrowRoundForward />
              </button> */}
            </div>
            <h2>Arcade</h2>
          </article>
          <article className='single-highlight'>
            <img src={highlight2} alt='arcade' className='highlight-img' />
            <h2>Bowling</h2>
            <div className='highlight-content'>
              <h4 className='title'>Bowling:</h4>
              <p>
                Roll into excitement at BOOM ROOM, home to Cairo's first
                gamified bowling alley. With state-of-the-art lanes and a lively
                ambiance, our bowling experience is perfect for group outings
                and unforgettable celebrations.
              </p>
              {/* <button className='btn-readMore'>
                READ MORE <IoIosArrowRoundForward />
              </button> */}
            </div>
          </article>
          <article className='single-highlight'>
            <img src={highlight3} alt='arcade' className='highlight-img' />
            <h2>
              Mini Golf
              <br />
              Court
            </h2>
            <div className='highlight-content'>
              <h4 className='title'>Mini Golf Court:</h4>
              <p>
                Embark on an unforgettable adventure at BOOM ROOM, Cairo's
                leading entertainment hub. Perfect for date nights and friends
                seeking fun-filled escapades.
              </p>
              {/* <button className='btn-readMore'>
                READ MORE <IoIosArrowRoundForward />
              </button> */}
            </div>
          </article>
          <article className='single-highlight'>
            <img src={highlight4} alt='arcade' className='highlight-img' />
            <h2>
              Live
              <br />
              Stage
            </h2>
            <div className='highlight-content'>
              <h4 className='title'>Live Stage:</h4>
              <p>
                Unleash your creativity at BOOM ROOM's dynamic live stage,
                Cairo's ultimate platform for artists, musicians, and comedians
                . Step into the spotlight at BOOM ROOM and make your mark on
                Egypt's vibrant entertainment scene.
              </p>
              {/* <button className='btn-readMore'>
                READ MORE <IoIosArrowRoundForward />
              </button> */}
            </div>
          </article>
          <article className='single-highlight'>
            <img src={highlight5} alt='arcade' className='highlight-img' />
            <h2>Darts</h2>
            <div className='highlight-content'>
              <h4 className='title'>Darts:</h4>
              <p>
                For the first time in Egypt, Boom Room brings you darts like
                you’ve never seen them before. Come and experience our unique
                gamified dart boards, bring your friends and see who comes out
                on top.
              </p>
              {/* <button className='btn-readMore'>
                READ MORE <IoIosArrowRoundForward />
              </button> */}
            </div>
          </article>
        </div>
      </section>
    </Bounce>
  );
};

export default Attractions;
