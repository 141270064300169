import React from 'react';
import { Bounce } from 'react-awesome-reveal';

import SocialLinks from '../SocialLinks/SocialLinks';

import Logo from '../../assets/footerLogo.png';

const Footer = () => {
  return (
    <footer className='col align-items-center'>
      <section className='footer-top row space-between align-items-center'>
        <div className='col'>
          <Bounce triggerOnce direction='left'>
            <div className='footer-logo'>
              <img src={Logo} alt='BoomRoom' />
            </div>
            <SocialLinks styleClass='footerIcons' />
          </Bounce>
        </div>
        <Bounce triggerOnce direction='right'>
          <div className='footer-text col'>
            <h3>
              Playtime <br />
              Isn’t Over.
            </h3>
            <h6>
              From <span>10:00</span> AM to <span>12:00</span> AM 7 days a week
            </h6>
          </div>
        </Bounce>
      </section>
      <section className='copyright'>
        © 2024 BOOMROOM <span>Developed by <a href="https://creteagency.com" target="_blank" style={{color: "#ef8e21"}}>Crete Agency</a></span>
      </section>
    </footer>
  );
};

export default Footer;
