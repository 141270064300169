import React from 'react';
import { useMediaQuery } from 'react-responsive';

import SloganBG from '../../assets/Slogan-playtime.png';
import SloganMobile from '../../assets/SloganMobile.png';
import SloganTablet from '../../assets/Slogan-tablet.png';

const Slogan = () => {
  // responsive media query
  const isTablet = useMediaQuery({ query: '(max-width: 960px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

  const bgImg = {
    background: `url(${
      isMobile ? SloganMobile : isTablet ? SloganTablet : SloganBG
    }) repeat-x`,
    width: isMobile ? `1410px` : isTablet ? '4311px' : '6537px',
    height: isMobile ? '13px' : isTablet ? '40px' : `60px`,
  };

  //  const bgImg = {
  //    background: `url(${isMobile ? SloganMobile : SloganBG}) repeat-x`,
  //    width: isMobile ? `1410px` : '6537px',
  //    height: isMobile ? '13px' : `60px`,
  //  };
  return (
    <section className='slogan-section'>
      <div className='sliding-background' style={bgImg}></div>
    </section>
  );
};

export default Slogan;
