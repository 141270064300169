import React from 'react';
import { Bounce } from 'react-awesome-reveal';

import flwr from '../../assets/flwr.png';
import crcleGif from '../../assets/slogan.png';

const Intro = () => {
  const inlineStyle = {
    backgroundImage: `url(${crcleGif})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };
  return (
    <section className='intro'>
      <img src={flwr} alt='BoomRoom' className='flwr' />
      <div className='wrapper head-content row space-between align-items-center height-100'>
        <div className='text'>
          <Bounce triggerOnce cascade damping={0.5} direction='left'>
            <h1>
              <span>Welcome to</span>
              <span>Boom Room</span>
            </h1>

            <h6>Egypt’s First and Biggest Social Entertainment Hub</h6>
          </Bounce>
        </div>
        <div className='gif-contain'>
          <div className='anmate-crcl' style={inlineStyle}>
            <h4>
              <span>NOW Open!</span>
              <span>at Open Air Mall,</span>
              <span>Madinaty</span>
            </h4>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
