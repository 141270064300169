import React from 'react';
import { ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './components/Header/Header';
import Intro from './components/Intro/Intro';
import AboutUs from './components/AboutUs/AboutUs';
import Rooms from './components/Rooms/Rooms';
import Attractions from './components/Attractions/Attractions';
import Slogan from './components/Slogan/Slogan';
import FnB from './components/FnB/FnB';
import ContactUs from './components/ContactUs/ContactUs';
import Footer from './components/Footer/Footer';

const App = () => {
  return (
    <>
      <Header />
      <Intro />
      <AboutUs />
      <Rooms />
      <Attractions />
      <Slogan />
      <FnB />
      <ContactUs />
      <Footer />
      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme='dark'
        transition={Bounce}
      />
    </>
  );
};

export default App;
