import React from 'react';

const Bowling = ({ ...props }) => {
  return (
    <svg
      width={186}
      height={168}
      viewBox='0 0 186 168'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        {...props}
        d='M92.9618 167.945C85.5444 167.945 79.9864 153.707 73.1259 152.116C65.967 150.455 54.2942 160.637 47.9805 157.465C41.4878 154.204 43.4665 139.131 38.147 134.527C32.7282 129.834 17.3864 133.244 13.4889 127.418C9.58133 121.572 19.1761 109.591 17.0981 102.869C15.0698 96.3069 0.205255 91.3355 0.195312 84.1966C0.195312 77.0477 15.0598 72.0564 17.0683 65.4941C19.1364 58.7728 9.52167 46.8016 13.4292 40.9453C17.3168 35.1089 32.6685 38.4994 38.0774 33.7964C43.3869 29.183 41.3785 14.1097 47.8711 10.8385C54.1749 7.66671 65.8676 17.8283 73.0165 16.1579C79.877 14.5571 85.4152 0.309015 92.8226 0.299072C100.24 0.299072 105.798 14.5471 112.659 16.128C119.817 17.7885 131.49 7.60704 137.804 10.7689C144.297 14.0202 142.318 29.1034 147.637 33.7069C153.046 38.4 168.398 34.9896 172.296 40.816C176.203 46.6624 166.608 58.6435 168.686 65.3649C170.715 71.9371 185.589 76.8985 185.599 84.0474C185.599 91.1963 170.734 96.1777 168.716 102.75C166.648 109.471 176.263 121.442 172.365 127.289C168.477 133.125 153.126 129.735 147.717 134.438C142.407 139.051 144.406 154.124 137.923 157.396C131.629 160.567 119.927 150.406 112.778 152.076C105.917 153.677 100.379 167.925 92.9717 167.935L92.9618 167.945Z'
        fill='#2E2C2D'
      />
    </svg>
  );
};

export default Bowling;
