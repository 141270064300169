import React from 'react';

const Golf = ({ ...props }) => {
  return (
    <svg
      width={168}
      height={168}
      viewBox='0 0 168 168'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        {...props}
        d='M83.8814 167.945C130.175 167.945 167.704 130.416 167.704 84.122C167.704 37.8279 130.175 0.299072 83.8814 0.299072C37.5872 0.299072 0.0584412 37.8279 0.0584412 84.122C0.0584412 130.416 37.5872 167.945 83.8814 167.945Z'
        fill='#2E2C2D'
      />
    </svg>
  );
};

export default Golf;
