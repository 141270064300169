import React from 'react';
import { Link } from 'react-scroll';

import { IoIosArrowRoundForward } from 'react-icons/io';
import logo from '../../assets/Logo.png';

const Header = () => {
  return (
    <header>
      <div className='wrapper row space-between align-items-center'>
        <img src={logo} alt='BoomRoom' className='logo' />
        <Link
          className='btn-contact'
          to='contact-us'
          smooth={true}
          duration={1000}
        >
          GET IN TOUCH <IoIosArrowRoundForward />
        </Link>
      </div>
    </header>
  );
};

export default Header;
