import React from 'react';

import FB from '../../assets/facebook.svg';
import Insta from '../../assets/insta.svg';
import TikTok from '../../assets/tiktok.svg';

const SocialLinks = ({ styleClass }) => {
  return (
    <ul className={styleClass}>
      <li>
        <a href='https://www.facebook.com/share/JXWPUv4uPkZrnqWD/?mibextid=LQQJ4d'>
          <img src={FB} alt='facebook' />
        </a>
      </li>
      <li>
        <a href='https://www.instagram.com/boomroomeg'>
          <img src={Insta} alt='instagram' />
        </a>
      </li>
      <li>
        <a href='https://www.tiktok.com/@boomroomeg'>
          <img src={TikTok} alt='tiktok' />
        </a>
      </li>
    </ul>
  );
};

export default SocialLinks;
