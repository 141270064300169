import React from 'react';

const LiveStage = ({ ...props }) => {
  return (
    <svg
      width={333}
      height={193}
      viewBox='0 0 333 193'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        {...props}
        d='M166.575 193C74.6239 193.076 0.0302083 149.931 2.06258e-05 96.6291C-0.0452609 43.3275 74.473 0.0607892 166.425 6.40226e-05C258.376 -0.0606612 332.955 43.0846 333 96.3709C333.03 149.672 258.527 192.939 166.575 193Z'
        fill='#2E2C2D'
      />
    </svg>
  );
};

export default LiveStage;
