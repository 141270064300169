import React from 'react';
import { Bounce } from 'react-awesome-reveal';

import karaoke from '../../assets/room-1.png';
import playstation from '../../assets/room-2.png';
import instagram from '../../assets/room-3.png';

const Rooms = () => {
  return (
    <Bounce triggerOnce direction='right'>
      <section className='rooms'>
        <article className='room img-within'>
          <img src={karaoke} alt='karaoke' />
          <h4>Karaoke</h4>
        </article>
        <article className='room bg'>
          <h3>
            Three
            <br />
            Private
            <br />
            Rooms
          </h3>
        </article>
        <article className='room img-within'>
          <img src={playstation} alt='playstation' />
          <h4>Playstation</h4>
        </article>
        <article className='room bg sm'>
          <h3>
            <span>Karaoke</span>
            <span>PlayStation</span>
            <span>Instagrammable Moments</span>
          </h3>
        </article>
        <article className='room img-within'>
          <img src={instagram} alt='instagram' />
          <h4>Instagrammable Moments</h4>
        </article>
      </section>
    </Bounce>
  );
};

export default Rooms;
