import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { toast, Bounce } from 'react-toastify';
import { Bounce as BouncyScroll } from 'react-awesome-reveal';

import Axios from '../../axios/axios';

import { IoIosArrowRoundForward } from 'react-icons/io';

import contactBG from '../../assets/contactUsBG.png';
import contactBGTablet from '../../assets/contactBGTablet.png';
import contactBGMobile from '../../assets/contactBGMobile.png';

const ContactUs = () => {
  const [contactInfo, setContactInfo] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const isTablet = useMediaQuery({ query: '(max-width: 960px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 415px)' });

  const handleChange = (e) => {
    const contact = {
      ...contactInfo,
      [e.target.name]: e.target.value,
    };
    setContactInfo(contact);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await Axios.post(
        `/contact-us`,
        {
          name: contactInfo.name,
          email: contactInfo.email,
          phone: contactInfo.phone,
          message: 'Hi there, I want to Connect.',
        },
        {
          headers: { 'content-type': 'multipart/form-data' },
        }
      );
      setContactInfo({
        name: '',
        email: '',
        phone: '',
      });
      console.log(data.data);
      toast.success(`🦄 ${data.data}`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        transition: Bounce,
      });
    } catch (error) {
      console.log(error);
      toast.error(
        `🦄 ${Object.values(error?.response?.data?.errors)?.[0]?.[0]}`,
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'dark',
          transition: Bounce,
        }
      );
    }
  };

  const bgImg = {
    backgroundImage: `url(${
      isMobile ? contactBGMobile : isTablet ? contactBGTablet : contactBG
    })`,
    backgroundRepeat: 'repeat-x',
    backgroundPosition: 'center',
    transition: 'all 0.3s ease-in-out',
  };
  return (
    <section className='contact-us' id='contact-us'>
      <div
        className='contact-us-wrap row justify-center align-items-center width-100 height-100'
        style={bgImg}
      >
        <BouncyScroll
          triggerOnce
          direction='down'
          className='row justify-center width-100'
        >
          <div className='contact-form row space-between align-items-center'>
            <div className='text'>
              <h3>
                <i className='first-span'>Get in</i>
                <span>touch </span>
                <span className='last-span'>with us!</span>
              </h3>
            </div>
            <form onSubmit={handleSubmit}>
              <div className='form-group'>
                <input
                  type='text'
                  name='name'
                  id='name'
                  placeholder='What’s your name?'
                  value={contactInfo.name}
                  className='form-control'
                  onChange={handleChange}
                />
              </div>
              <div className='form-group'>
                <input
                  type='text'
                  name='email'
                  id='email'
                  placeholder='What’s your E-mail address?'
                  value={contactInfo.email}
                  className='form-control'
                  onChange={handleChange}
                />
              </div>
              <div className='form-group'>
                <input
                  type='text'
                  name='phone'
                  id='phone'
                  placeholder='What’s your phone number?'
                  value={contactInfo.phone}
                  className='form-control'
                  onChange={handleChange}
                />
              </div>
            </form>
          </div>
          <button className='btn-submit' type='submit' onClick={handleSubmit}>
            SUBMIT <IoIosArrowRoundForward />
          </button>
        </BouncyScroll>
      </div>
    </section>
  );
};

export default ContactUs;
